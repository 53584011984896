
import $ from "jquery";

import { Stroke, Style, Circle, Fill, Icon } from 'ol/style';

// export const API_WEBSOCKET_STRING = "ws://10.152.64.60:9999/ws"
// export const API_CONNECTION_STRING = "http://10.152.64.60:9999/"
export const API_WEBSOCKET_STRING = ""
export const API_CONNECTION_STRING = "https://api.micro-grid.au/"
export const API_LAYERS = [
  ["network", "LV Network", {image: true}, {params: "v=230", minZoom: 16 }],
  ["network", "11KV Network", {image: true}, {params: "v=11000", minZoom: 14 }],
  ["network", "33KV Network", {image: true}, {params: "v=33000", minZoom: 11 }],
  ["network", "110KV Network", {image: true}, {params: "v=110000" }],
  ["network", "132KV Network", {image: true}, {params: "v=132000" }],
  ["structures", "Poles", {image: true}, {params: "c=POLE", minZoom: 18 }],
  ["structures", "Pillars", {image: true}, {params: "c=PILLAR", minZoom: 18 }],
  ["structures", "Distribution Substations", {image: true}, {params: "c=SUB", minZoom: 16 }],
  ["structures", "Zone Substations", {image: false}, {params: "c=ZSUB", minZoom: 11 }],
  ["lotplans", "Lotplans", {image: true}, {params: "", minZoom: 18 }],
]

export const DEFAULT_CENTER = [16305945.73750275, -2206013.7191165173];
export const DEFAULT_ZOOM = 6.1;
export const DEFAULT_APPSTATE = {
  selection: {
    selectionType: "none",
  },
  touch: false
}

export const LOTPLAN_MINZOOM = 18;

export const LOTPLAN_STYLE = new Style({
  fill: new Fill({
    color: 'rgba(255,255,255,0.4)',
  }),
  stroke: new Stroke({
    color: '#3399CC',
    width: 1.25,
  }),
})

export const FAKE_DATA = {
  housePerPole: 2,
  houseUsage: 18,
  solarKwh: 33.4,
  solarPct: 0.3,
  batteryPct: 0.05,
  batteryKwh: 7,
}

export const SORT_ORDER = [
  132000,
  110000,
  33000,
  "ZONE",
  11000,
  "SUB",
  230,
  "PILLAR",
  "POLE"
]

export const SELECTION_TOLERANCE = 10;
export const SELECTION_DEPTH = [
  "POLE",
  "FDR",
  "TFR",
  "TFDR",
  "ZONE"
]

export const FEEDER_MINZOOM_BY_VOLTAGE = {
  230: 16,
  11000: 12,
  33000: 10,
}

export const STRUCTURE_MINZOOM_BY_CODE = {
  "POLE": 18,
  "PILLAR": 18,
  "SUB": 15,
}

export const FEEDER_STYLE_BY_VOLTAGE = {
  230: () => new Style({
    stroke: new Stroke({
      color: '#f768a1',
      width: 1.5,
    }),
  }),
  11000: () => new Style({
    stroke: new Stroke({
      color: '#c51b8a',
      width: 1.8,
    }),
  }),
  33000: () => new Style({
    stroke: new Stroke({
      color: '#7a0177',
      width: 2,
    }),
  }),
  110000: () => new Style({
    stroke: new Stroke({
      color: '#756bb1',
      width: 2,
    }),
  }),
  132000: () => new Style({
    stroke: new Stroke({
      color: '#54278f',
      width: 2,
    }),
  }),
}

export const STRUCTURE_STYLE_BY_CODE = {
  "POLE": () => new Style({
    image: new Circle({
      radius: 3,
      fill: new Fill({color: '#006837'})
    })
  }),
  "PILLAR": () => new Style({
    image: new Circle({
      radius: 3,
      fill: new Fill({color: '#006837'})
    })
  }),
  "SUB": () => new Style({
    image: new Circle({
      radius: 4,
      fill: new Fill({color: '#bd0026'}),
      stroke: new Stroke({color: '#f03b20'})
    })
  }),
}
