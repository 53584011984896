/**
 * Handles the selection of grid elements.
 * 
 * When an element is selected, it is highlighted by giving it a border. Then,
 * the selection api endpoint is called which returns GeoJSON returning all the
 * elements which were selected. 
 * 
 * The returned elements are rendered on a separate layer with custom styles for
 * all the elements.
 * 
 * The elements which are selected are also hidden on their respective layers
 */
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON.js';
import $ from "jquery";

// Local
import * as consts from './consts.js'
import { selectGridElement, hideSelectionOverlay, refreshSelectedElements } from './ui.js'
import { getHighlightLayer, getSelectionLayer } from './layers.js'
import { onSelectionChange } from './sockets.js'


const abortController = new AbortController();
const abortSignal = abortController.signal;

var appState;
var map;

var layerSelectionInfo = {
  "LV Network": {'type': 'feeder'},
  "11KV Network": {'type': 'feeder'},
  "33KV Network": {'type': 'feeder'},
  "110KV Network": {'type': 'feeder'},
  "132KV Network": {'type': 'feeder'},
  "Poles": {'type': 'structure'},
  "Pillars": {'type': 'structure'},
  "Distribution Substations": {'type': 'structure'},
  "Zone Substations": {'type': 'structure'},
  "Lotplans": {'type': 'lotplan'},
}

/**
 * Returns the selection state given the feature and layer selected.
 * 
 * returns: {
 *  "type": "lotplan"/"feeder"/"structure"
 *  "id": 123 // database ID for the selected structure
 * }
 * 
 * @param {*} feature 
 * @param {*} layer 
 */
function getSelectionInfo(feature, layer) {
  // If there is no layer name, then it is the selection layer. Get the type
  // from the feature instead
  var type;
  if (layer.get('name') == null) {
    type = feature.get('type');
  } else {
    type = layerSelectionInfo[layer.get('name')].type;
  }

  return {
    "id": feature.get("@id"),
    "type": type,
  }
}

function handleSelectionResponse(json) {
  // Create a vector source from the GeoJSON
  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(json, {featureProjection: map.getView().getProjection()}),
  });

  const selectionLayer = getSelectionLayer();

  selectionLayer.setSource(vectorSource);

  refreshSelectedElements(vectorSource.getFeatures(), (element) => {}, (element) => {});

  var extent = vectorSource.getExtent();
  var size = map.getSize();

  if (!appState.slaveSelection || appState.slaveSelection == 'N') {
    map.getView().fit(extent, {
      "size": size,
      "padding": [100,100,100,500],
      "duration": 500,
      "minResolution": 0.3,
    }); 
  }
}

export function updateSelection(selection, doPopout) {
  appState.selection = selection;
  localStorage.setItem("appState", JSON.stringify(appState));
  refreshSelection(doPopout);
}

/**
 * This method will take the selection info from the appstate and perform the
 * required tasks to select those elements. That will be one of the following:
 * 
 * selection = null or selectionType = "none":
 *  - Delete the Selection Layer vector source
 *  - Show all layers
 *  - Clear info from tabs
 *  - Disable all tabs
 * 
 * selectionType = "single"
 *  - Load new vector source
 *  - Hide all layers of the same type (ie, if selecting a feeder, hide all feeders)
 *  - Add info to tabs
 *  - Enable info, nav and clear tabs
 * 
 * selectionType = "parents" or "children"
 *  - Load new vector source
 *  - Hide all layers except Zone substation layer
 *  - Add info to tabs
 *  - Enable info, elements and clear tabs
 */
export function refreshSelection(doPopout=true) {
  hideSelectionOverlay();

  const selectionLayer = getSelectionLayer();

  if (appState.selection == null || appState.selection.selectionType == "none") {
    selectionLayer.setSource(new VectorSource());

    // $("#homeTab").addClass("disabled");
    $("#navigateTab").addClass("disabled");
    $("#elementsTab").addClass("disabled");
    $("#deselectTab").addClass("disabled");

    for (let layer of map.getLayers().getArray()) {
      layer.setVisible(true);
    }

    refreshSelectedElements([], null, null);
  } else if (appState.selection.selectionType == "single") {
    // $("#homeTab").removeClass("disabled");
    $("#navigateTab").removeClass("disabled");
    $("#elementsTab").removeClass("disabled");
    $("#deselectTab").removeClass("disabled");

    var type = appState.selection.node.type;
    for (let layer of map.getLayers().getArray()) {
      if (layer.get('name') != null && layer.get('name') != 'Zone Substations' && layerSelectionInfo[layer.get('name')].type == type) {
        layer.setVisible(false);
      } else {
        layer.setVisible(true);
      }
    }

    // Request selection GeoJSON directly from API
    fetch(consts.API_CONNECTION_STRING + "selection", {
      method: "POST",
      body: JSON.stringify({
          "type": appState.selection.node.type,
          "id": appState.selection.node.id,
          "showExtra": appState.selection.preferences
      }),
    }).then((response) => response.json()).then(handleSelectionResponse);
  } else if (appState.selection.selectionType == "parents") {
    // $("#homeTab").removeClass("disabled");
    $("#navigateTab").addClass("disabled");
    $("#elementsTab").removeClass("disabled");
    $("#deselectTab").removeClass("disabled");

    for (let layer of map.getLayers().getArray()) {
      if (layer.get('name') != null && layer.get('name') != 'Zone Substations') {
        layer.setVisible(false);
      } else {
        layer.setVisible(true);
      }
    }

    // Request selection GeoJSON directly from API
    fetch(consts.API_CONNECTION_STRING + "parents", {
      method: "POST",
      body: JSON.stringify({
          "type": appState.selection.node.type,
          "id": appState.selection.node.id,
          "depth": appState.selection.depth,
          "showExtra": appState.selection.preferences
      }),
    }).then((response) => response.json()).then(handleSelectionResponse);
  } else if (appState.selection.selectionType == "children") {
    // $("#homeTab").removeClass("disabled");
    $("#navigateTab").addClass("disabled");
    $("#elementsTab").removeClass("disabled");
    $("#deselectTab").removeClass("disabled");

    for (let layer of map.getLayers().getArray()) {
      if (layer.get('name') != null && layer.get('name') != 'Zone Substations') {
        layer.setVisible(false);
      } else {
        layer.setVisible(true);
      }
    }

    // Request selection GeoJSON directly from API
    fetch(consts.API_CONNECTION_STRING + "children", {
      method: "POST",
      body: JSON.stringify({
          "type": appState.selection.node.type,
          "id": appState.selection.node.id,
          "depth": appState.selection.depth,
          "showExtra": appState.selection.preferences
      }),
    }).then((response) => response.json()).then(handleSelectionResponse);
  }

  // Very crude way to show details tab on selecting...
  if (!(appState.selection == null || appState.selection.selectionType == "none") && doPopout) {
    if (!$("#elementsTab").hasClass("active")) {
      $("#elementsTab a").click();
    }
  }
}

/**
 * Given a map feature, performs a single selection against that map feature
 * @param {*} feature 
 * @param {*} layer 
 */
export function featureSelected(feature, layer) {
  // cancel any pending fetch
  //abortController.abort("New selection");

  // Update selection info
  appState.selection = {
    "node": getSelectionInfo(feature, layer),
    "selectionType": "single",
    "preferences": {
      "mv_fuses": true,
      "lv_fuses": true,
      "solar_groups": true,
      "property_connections": true
    }
  }

  // Save appstate
  localStorage.setItem("appState", JSON.stringify(appState));
  onSelectionChange(appState.selection);

  // Refresh
  refreshSelection();
}

/**
 * Based on the selected element, preform a children search
 */
function selectChildren() {
  if (appState.selection.selectionType != 'single') {
    return;
  }

  // Update selection info
  appState.selection.selectionType = "children";
  appState.selection.depth = $("#childrenDepth").val();

  // Save appstate
  localStorage.setItem("appState", JSON.stringify(appState));
  onSelectionChange(appState.selection);

  // Refresh
  refreshSelection();
}

/**
 * Based on the selected element, preform a parent search
 */
function selectParents() {
  if (appState.selection.selectionType != 'single') {
    return;
  }

  // Update selection info
  appState.selection.selectionType = "parents";
  appState.selection.depth = $("#parentDepth").val();

  // Save appstate
  localStorage.setItem("appState", JSON.stringify(appState));
  onSelectionChange(appState.selection);

  // Refresh
  refreshSelection();
}

function clearSelection() {
  appState.selection = {
    "selectionType": "none",
  }

  // Save appstate
  localStorage.setItem("appState", JSON.stringify(appState));
  onSelectionChange(appState.selection);

  // Refresh
  refreshSelection();
}

export default function registerListeners(m, as) {
  appState = as;
  map = m;

  refreshSelection();

  // Register tab and input listeners
  $("#deselectTab a").on('click', clearSelection);
  $("#selectChildren").on('click', selectChildren);
  $("#selectParents").on('click', selectParents);

  map.on('singleclick', function (e) {
    hideSelectionOverlay();

    map.forEachFeatureAtPixel(
      e.pixel,
      (feature, layer) => {
        selectGridElement(e.coordinate, {
          "onclick": () => { featureSelected(feature, layer) },
          "name": feature.get("friendly_name"),
          "id": feature.get("@id"),
          "layer": layer.get("name"),
          "onhover": () => {
            var highlightLayer = getHighlightLayer();
            var features = [feature];
            
            // If this is a feeder, select all feeders with the same name
            if (feature.get("feeder_name") != null) {
              layer.getSource().forEachFeature((f) => {
                const fn = feature.get("feeder_name");
                if (f.get("feeder_name") == fn) {
                  features.push(f);
                }
              })
            }
            
            features.forEach((f) => {
              highlightLayer.getSource().addFeature(f);
              layer.getSource().removeFeature(f);
            })

            return () => { 
              features.forEach((f) => {
                layer.getSource().addFeature(f);
                highlightLayer.getSource().removeFeature(f);
              })
            };
          }
        });
      },
      {
        hitTolerance: consts.SELECTION_TOLERANCE,
      },
    );
  });
}