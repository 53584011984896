import './style.css';
import './libraries/fontawesome-free-6.5.2-web/css/all.min.css';
import sidebarCSS from './libraries/sidebar/ol-sidebar.css?raw';
import sidebarCSSTouch from './libraries/sidebar/ol-sidebar-touch.css?raw';
import sidebarCSSHide from './libraries/sidebar/ol-sidebar-hide.css?raw';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Sidebar from './libraries/sidebar/ol-sidebar.js';
// import Geocoder from 'ol-geocoder';
import "bootstrap/scss/bootstrap.scss";
import $ from "jquery";

// LOCAL
import * as consts from './scripts/consts.js';
import createLayers from './scripts/layers.js';
import registerListeners from './scripts/select.js';
import generateOverlays from './scripts/ui.js';
import createSockets from './scripts/sockets.js';

const sidebarStyleTag = document.createElement('style');

document.head.appendChild(sidebarStyleTag);

var map;

// Contains the current state of the app including:
// - Selected element info
var appState = (localStorage.getItem("appState") == null) ? consts.DEFAULT_APPSTATE : JSON.parse(localStorage.getItem("appState"));

function reloadCss() {
  $("#sidebar").show();
  listenForTouchEvents();

  if (['RD', 'LD'].includes(appState.slaveSelection)){
    sidebarStyleTag.innerHTML = sidebarCSSHide;
  } else if (appState.touch) {
    sidebarStyleTag.innerHTML = sidebarCSSTouch;
  } else {
    sidebarStyleTag.innerHTML = sidebarCSS;
  }
}

function preventMultitouch(e) {
  if(e.touches && e.touches.length > 1){
    //the event is multi-touch
    //you can then prevent the behavior
    e.preventDefault();
  }
}

/**
 * This allows targets with the "draggable" class to be dragged around either
 * via mouse or touch input. When touch input mode is enabled, the side bar
 * will be shorter and can be dragged around
 * @param {*} e 
 * @returns 
 */
function listenForTouchEvents() {
  function filter(e) {
    preventMultitouch(e);

    let target = e.target;

    if (!target.classList.contains("draggable")) {
      return;
    }

    target = target.parentNode;

    target.moving = true;

    if (e.clientX) {
      target.oldX = e.clientX;
      target.oldY = e.clientY;
    } else {
      target.oldX = e.touches[0].clientX;
      target.oldY = e.touches[0].clientY;
    }

    target.oldLeft = window.getComputedStyle(target).getPropertyValue('left').split('px')[0] * 1;
    target.oldTop = window.getComputedStyle(target).getPropertyValue('top').split('px')[0] * 1;

    document.getElementById("sidebar-tabs").addEventListener("drag", dr);
    document.getElementById("sidebar-tabs").addEventListener("touchmove", dr);
    document.getElementById("sidebar-tabs").addEventListener("mousemove", dr);

    function dr(event) {
      event.preventDefault();

      if (!target.moving) {
        return;
      }
      if (event.clientX) {
        target.distX = event.clientX - target.oldX;
        target.distY = event.clientY - target.oldY;
      } else {
        target.distX = event.touches[0].clientX - target.oldX;
        target.distY = event.touches[0].clientY - target.oldY;
      }

      target.style.left = target.oldLeft + target.distX + "px";
      target.style.top = target.oldTop + target.distY + "px";
    }

    function endDrag() {
      console.log("End drag");
      target.moving = false;

      document.getElementById("sidebar-tabs").removeEventListener("drag", dr);
      document.getElementById("sidebar-tabs").removeEventListener("touchmove", dr);
      document.getElementById("sidebar-tabs").removeEventListener("mousemove", dr);
      document.getElementById("sidebar-tabs").removeEventListener("dragend", endDrag);
      document.getElementById("sidebar-tabs").removeEventListener("touchend", endDrag);
      document.getElementById("sidebar-tabs").removeEventListener("mouseup", endDrag);
    }

    document.getElementById("sidebar-tabs").addEventListener("dragend", endDrag);
    document.getElementById("sidebar-tabs").addEventListener("touchend", endDrag);
    document.getElementById("sidebar-tabs").addEventListener("mouseup", endDrag);
  }

  if (appState.touch) {
    console.log("Registering touch events");
    // document.onmousedown = filter;
    // document.ontouchstart = filter;
    // document.ondragstart = filter;

    document.getElementById("sidebar-tabs").addEventListener("dragstart", filter);
    document.getElementById("sidebar-tabs").addEventListener("touchstart", filter);
    document.getElementById("sidebar-tabs").addEventListener("mousedown", filter);
  } else {
    console.log("Unregistering touch events");

    document.onmousedown = null;
    document.ontouchstart = null;


    const d = document.getElementsByClassName("draggable");

    for (let i = 0; i < d.length; i++) {
      d[i].parentNode.style.left = null;
      d[i].parentNode.style.top = null;
    }
  }
}

/**
 * SOURCE: https://www.joshwcomeau.com/snippets/javascript/debounce/
 */
const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
}

function mapSetup() {
  document.getElementById("sidebar").addEventListener("touchstart", preventMultitouch);

  var prevCenter = JSON.parse(localStorage.getItem("center"));
  var prevZoom = JSON.parse(localStorage.getItem("zoom"));

  reloadCss()
  $("#isTouchMode").prop('checked', appState.touch);
  $("#isTouchMode").on("change", () => {
    appState.touch = $("#isTouchMode").is(':checked');
    localStorage.setItem("appState", JSON.stringify(appState));
    reloadCss()
  })

  map = new Map({
    target: 'map',
    overlays: generateOverlays(),
    layers: [
      new TileLayer({
        source: new OSM({
          // url: consts.API_CONNECTION_STRING + "tiles/{z}/{x}/{y}.png"
        })
      })
    ],

    // Default center to SEQ as we only have data here
    view: new View({
      center: (prevCenter == null) ? consts.DEFAULT_CENTER : prevCenter,
      zoom: (prevZoom == null) ? consts.DEFAULT_ZOOM : prevZoom,
      constrainOnlyCenter: true,
      enableRotation: false
    })
  });

  function onMoveEnd(evt) {
    const map = evt.map;
    const center = map.getView().getCenter();
    const zoom = map.getView().getZoom();

    localStorage.setItem("center", JSON.stringify(center));
    localStorage.setItem("zoom", JSON.stringify(zoom));
  }
  
  map.on('moveend', onMoveEnd);

  createLayers(map);
  registerListeners(map, appState);

  // const geocoder = new Geocoder('nominatim', {
  //   provider: 'osm',
  //   lang: 'en',
  //   placeholder: 'Search for ...',
  //   countrycodes: 'au',
  //   limit: 5,
  //   debug: false,
  //   keepOpen: true,
  //   preventMarker :true,
  //   defaultFlyResolution: 60
  // });

  // map.addControl(geocoder);

  // var geocoderElement = document.getElementById("gcd-container");
  // document.getElementById("searchBar").appendChild(geocoderElement);

  // var handleSearchInput = debounce((evt) => {
  //   //Trigger Enter event on textbox
  //   var input = document.getElementById('gcd-input-query');
  //   var evt = new CustomEvent('keydown');
  //   evt.which = evt.keyCode = 13;
  //   input.dispatchEvent(evt);

  //   console.log(input);
  // }, 250)

  // $("#gcd-input-query").on("input", handleSearchInput)

  var sidebar = new Sidebar({
    element: 'sidebar',
    position: 'left'
  });

  map.addControl(sidebar);

  // Register socket listeners
  // createSockets(map, appState);


  // Hide legend except for main illumicave screen
  if (![null, "N", "M"].includes(appState.slaveSelection)) {
    $("#legend").hide();
  }
}

function setup() {
  setTimeout(mapSetup, 1000);
}

setup();