import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import GeoJSON from 'ol/format/GeoJSON.js';
import { Stroke, Style, Circle, Fill, Icon } from 'ol/style';
import * as olProj from 'ol/proj';
import $ from "jquery";
import ticon from "../icons/transformer.png";

// LOCAL
import * as consts from './consts.js';

var highlightLayer;
var selectionLayer;

// Dict which returns a layer style function from the layer name
const layerStyles = {
  // Distribution
  "LV Network": consts.FEEDER_STYLE_BY_VOLTAGE[230],
  "11KV Network": consts.FEEDER_STYLE_BY_VOLTAGE[11000],

  // Short Distance Transmission
  "33KV Network": consts.FEEDER_STYLE_BY_VOLTAGE[33000],

  // Long Distance Transmission
  "110KV Network": consts.FEEDER_STYLE_BY_VOLTAGE[110000],
  "132KV Network": consts.FEEDER_STYLE_BY_VOLTAGE[132000],

  "Poles": consts.STRUCTURE_STYLE_BY_CODE["POLE"],
  "Pillars": consts.STRUCTURE_STYLE_BY_CODE["PILLAR"],
  "Distribution Substations": consts.STRUCTURE_STYLE_BY_CODE["SUB"],

  // function so the size can be based on the current zoom level
  "Zone Substations": function(feature, resolution) {
    return new Style({
      image: new Icon({
        src: ticon,
        color: '#BADA55',
        scale: 0.1/Math.pow(resolution, 1/4),
        size: [512,612],
        offset: [-40,40],
      }),
    });
  },
};

/**
 * Helper for creating vector layers with vector sources
 * 
 * @param {*} url 
 * @param {*} layerName 
 * @param {*} styling 
 */
function createVectorLayer(url, layerName, styling, properties, params) {
  var layer;

  if (properties.image) {
    layer = new VectorImageLayer({
      style: styling,
    });
  } else {
    layer = new VectorLayer({
      style: styling,
    });
  }

  layer.setProperties(params);

  var vectorSource = new VectorSource({
    format: new GeoJSON(),
    url: function(extent) {
    // Convert the extent from internal units to lon/lat
    extent = [
      olProj.toLonLat(extent.slice(0,2)),
      olProj.toLonLat(extent.slice(2,4)),
    ].flat()

    // use get url requesting. There is also the post option, but we would
    // need to create our own loader. That could be a future enhancement for
    // when we do caching and split voltages into layers
    
    return url + '?bbox=' + extent.join(',') + '&' + params.params;
    },

    /*
    VectorSource function is pretty special and accepts an extent and resolution
    but then only cares about the extent as to whether to load the source 
    again... bruh

    So, if we want it to refresh on zoom in, then we need to do our loading
    in the strategy function. At the moment, we will just limit the low voltage
    networks to the lower zoom levels so it doesn't matter
    */
    strategy: function(extent) {
    return [extent];
    }
  });

  layer.setSource(vectorSource);
  layer.set("name", layerName);

  return layer;
}

export function getHighlightLayer() {
  return highlightLayer;
}

export function getSelectionLayer() {
  return selectionLayer;
}

export default function createLayers(map) {
    // Add api layers
    for (const layerInfo of consts.API_LAYERS) {
      var layer = createVectorLayer(
        consts.API_CONNECTION_STRING + layerInfo[0], 
        layerInfo[1], 
        layerStyles[layerInfo[1]], 
        layerInfo[2], 
        layerInfo[3]
      );
  
      map.addLayer(layer);
    }

    // Add highlight layer
    highlightLayer = new VectorLayer({
      source: new VectorSource(),
      map: map,
      style: () => new Style({
        stroke: new Stroke({
          color: '#ff0000',
          width: 4,
        }),
        image: new Circle({
          radius: 8,
          fill: new Fill({color: '#ff0000'})
        }),
      })
    });

    // Add selection layer
    selectionLayer = new VectorImageLayer({
      source: new VectorSource(),
      style: (feature, resolution) => {
        var ignoreZoom = false;
        if (selectionLayer.getSource().getFeatures().lengthh <= 50) {
          ignoreZoom = true;
        }
        var zoom = map.getView().getZoomForResolution(resolution);
        // Items added to this layer will have an additional field for the type
        // of item
        if (feature.get('type') == "structure" && feature.get('code') != 'ZSUB') {
          if (!ignoreZoom && feature.get('code') in consts.STRUCTURE_MINZOOM_BY_CODE &&
              zoom < consts.STRUCTURE_MINZOOM_BY_CODE[feature.get('code')]) {
            return null;
          }

          return consts.STRUCTURE_STYLE_BY_CODE[feature.get('code')]();
        } else if (feature.get('type') == "feeder") {
          if (!ignoreZoom && parseInt(feature.get('voltage')) in consts.FEEDER_MINZOOM_BY_VOLTAGE &&
              zoom < consts.FEEDER_MINZOOM_BY_VOLTAGE[parseInt(feature.get('voltage'))]) {
            return null;
          }

          return consts.FEEDER_STYLE_BY_VOLTAGE[parseInt(feature.get('voltage'))]();
        } else if (feature.get('type') == "lotplan") {
          if (!ignoreZoom && zoom < consts.LOTPLAN_MINZOOM) {
            return null;
          }

          return consts.LOTPLAN_STYLE;
        } 
      }
    });
    
    map.addLayer(selectionLayer);
}