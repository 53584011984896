/**
 * This file contains everything for the various UI elements. This includes:
 * - Configuring the side bar and selection popup
 * - Methods for bringing up the selection popup, resetting and adding to the popup
 * - Methods for refreshing the side bar based on the selection
 * 
 * Much of this code is based on this OpenLayers example: 
 * https://openlayers.org/en/latest/examples/popup.html
 */
import Overlay from 'ol/Overlay.js';
import $ from "jquery";

// Local
import * as consts from './consts.js'
import { getHighlightLayer, getSelectionLayer } from './layers.js';
import { featureSelected } from './select.js';

/**
 * HTML elements
 */

const selectContainer = document.getElementById('select-popup');
const selectContent = document.getElementById('select-content');
const selectCloser = document.getElementById('select-closer');
const selectedElementsContent = document.getElementById('elementsContent');
var selectedElements = [];

/**
 * Create an overlay to anchor the popup to the map.
 */
const selectOverlay = new Overlay({
  element: selectContainer,
  autoPan: {
    animation: {
    duration: 250,
    },
  },
});

/**
 * Add a click handler to hide the popup.
 * @return {boolean} Don't follow the href.
 */
selectCloser.onclick = function () {
  selectOverlay.setPosition(undefined);
  selectCloser.blur();
  return false;
};

/**
 * Function to generate all overlays and return a list of overlays
 */
export default function generateOverlays() {
  return [selectOverlay];
}

export function hideSelectionOverlay() {
  selectOverlay.setPosition(null);
}

/**
 * This method will add an element to the select overlay, hyperlinked to select
 * the element.
 * 
 * If the coordinate provided is different to the current overlay coordinate, 
 * the overlay will be cleared of elements first, otherwise the element is
 * appended.
 * 
 * @param {ol/coordinate} coordinate the coordinate the element was selected at
 * @param {*} element the element to select
 */
export function selectGridElement(coordinate, element) {
  if (selectOverlay.get("position") != coordinate) {
    selectContent.innerHTML = '';
    selectOverlay.setPosition(coordinate);
    selectedElements = [];
  }

  for (let e of selectedElements) {
    if (e.layer == element.layer && e.name == element.name) {
      return;
    }
  }

  selectedElements.push(element);

  const newLi = document.createElement("li");
  newLi.innerHTML = element.name;
  newLi.onclick = element.onclick;
  newLi.onmouseover = (e) => {
    const undo = element.onhover();

    if (undo != null) {
      newLi.onmouseout = undo;
    }
  }

  selectContent.appendChild(newLi);
}

/**
 * This method brings up a popup at the given location with the given element's
 * details
 */
export function showElement(coordinate, element) {
  return;
}

function createElement(e, i) {
  
  var element = `<div class="accordion-item">
    <h2 class="accordion-header" id="heading<NUMBER>">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse<NUMBER>" aria-expanded="true" aria-controls="collapse<NUMBER>">
        
      </button>
    </h2>
    <div id="collapse<NUMBER>" class="accordion-collapse collapse show" aria-labelledby="heading<NUMBER>" data-bs-parent="#elementsContent">
      <div class="accordion-body" id="innerbody">
      </div>
    </div>
  </div>`;

  return $(element);
}

/**
 * This method handles updating the UI on a selection change
 * 
 * it takes a list of elements and an onselect and onshow function which
 * each accept an element
 */
export function refreshSelectedElements(elements, onselect, onshow) {
  selectedElementsContent.innerHTML = '';

  elements.sort((a,b) => {
    var ai = a.get('voltage') ? consts.SORT_ORDER.indexOf(a.get('voltage')) : consts.SORT_ORDER.indexOf(a.get('code'));
    var bi = b.get('voltage') ? consts.SORT_ORDER.indexOf(b.get('voltage')) : consts.SORT_ORDER.indexOf(b.get('code'));

    if (a.get('type') == 'lotplan' && b.get('type') != 'lotplan') {
      return 1;
    }

    if (b.get('type') == 'lotplan' && a.get('type') != 'lotplan') {
      return -1;
    }

    return ai-bi;
  });

  var houses = 0;

  elements.forEach(e => {
    if (e.get('code') == 'PILLAR' || e.get('code') == 'POLE') {
      houses += consts.FAKE_DATA.housePerPole;
    }
  });

  if (houses == 0) {
    $("#selectionDetails").hide();
  } else {
    $("#selectionDetails").show();
    var solarKwh = consts.FAKE_DATA.solarPct*houses*consts.FAKE_DATA.solarKwh;
    var batteryKwh = consts.FAKE_DATA.batteryPct*houses*consts.FAKE_DATA.batteryKwh;
    var demand = houses*consts.FAKE_DATA.houseUsage;

    $("#houseCount").html(houses)
    $("#solarCapacity").html(`${Math.round(solarKwh)} kWh`)
    $("#batteryCapacity").html(`${Math.round(batteryKwh)} kWh`)
    $("#demand").html(`${Math.round(demand)} kWh`)
  }

  for (let e of elements) {
    `<a href="#" class="list-group-item list-group-item-action">A second link item</a>`
    const newA = document.createElement("a");
    newA.href="#";
    newA.classList.add("list-group-item");
    newA.classList.add("list-group-item-action");

    var underground = e.get("underground") == true ? "(Underground)" : "";
    newA.innerHTML = `${e.get("friendly_name")} ${underground}`

    const layer = getSelectionLayer();
    const feature = e;
    newA.addEventListener('mouseover', () => {
      var highlightLayer = getHighlightLayer();
      var features = [feature];
      
      features.forEach((f) => {
        highlightLayer.getSource().addFeature(f);
        layer.getSource().removeFeature(f);
      })

      newA.addEventListener('mouseout', () => { 
        features.forEach((f) => {
          layer.getSource().addFeature(f);
          highlightLayer.getSource().removeFeature(f);
        })
      });
    })

    newA.addEventListener('click', () => {
      getHighlightLayer().getSource().removeFeature(e);
      featureSelected(feature, layer);
    });
    
    selectedElementsContent.appendChild(newA);
  }
}